import styled from 'styled-components';
import BaseDrawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import { DrawerTitle as BaseDrawerTitle } from '../Drawer/Drawer.styles';

export const Drawer = withStyles({
  paper: {
    width: '375px',
    padding: '13px 13px 18px',
    boxSizing: 'border-box',
    fontSize: '16px',
    lineHeight: '24px',
    '@media screen and (max-width: 767px)': {
      width: '90%',
    },
  },
})(BaseDrawer);

export const DrawerTitle = styled(BaseDrawerTitle)`
  position: relative;
`;

export const SecondaryTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  & + & {
    margin-top: 28px;
  }
`;

export const ContentWrapper = styled.div`
  max-height: 90vh;
  overflow-y: auto;
  width: 100%;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 13px;
  font-family: Larsseit;
  margin-bottom: 8px;
`;

export const StoreContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    font-weight: 400;
    font-size: 13px;
    font-family: Larsseit;
    text-decoration: underline;
  }
`;

export const CallText = styled.div`
  font-weight: 400;
  font-size: 13px;
  font-family: Larsseit;
`;
