import React from 'react';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  SecondaryTitle,
  TitleContainer,
  PrimaryTitle,
  HeaderContainer,
  Title,
  RightIcon,
  ContentWrapper,
  StoreContainer,
  Container,
  Text,
  CallText,
  LeftIcon,
} from './SizeGuideBottomSheet.styles';
import { SizesAndConversions } from '../SizeGuideConvertion/SizesAndConversions';

const NEED_US = `https://assistance.bergdorfgoodman.com/contact`;

export default function SizeGuideBottomSheet({
  title,
  secondaryTitle = '',
  sizeGuides,
  isSizeGuide,
  onClose,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleSizeSelector,
}) {
  return (
    <ContentWrapper>
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <HeaderContainer>
          <Grid container alignItems="center">
            <Grid item xs={1} container>
              <LeftIcon onClick={!isSizeGuide && handleSizeSelector}>
                {!isSizeGuide ? <ArrowBackIosIcon /> : null}
              </LeftIcon>
            </Grid>
            <Grid item xs={10} container alignItems="center">
              <Title>SIZE GUIDE</Title>
            </Grid>

            <Grid
              item
              xs={1}
              container
              style={{
                justifyContent: 'right',
              }}
            >
              <RightIcon onClick={onClose}>
                <CloseIcon />
              </RightIcon>
            </Grid>
          </Grid>
        </HeaderContainer>
        <TitleContainer>
          {secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
          <PrimaryTitle>{title}</PrimaryTitle>
        </TitleContainer>
      </div>
      <Container>
        <SizesAndConversions sizeGuides={sizeGuides} />
      </Container>

      <Container>
        <Text>Need more information?</Text>
      </Container>

      <StoreContainer>
        <a href={NEED_US} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
        <CallText>&nbsp;or&nbsp;call us&nbsp;</CallText>
        <a href="tel:+1.888.774.2424" decoration="none" weight="bold">
          1.888.774.2424
        </a>
      </StoreContainer>
    </ContentWrapper>
  );
}
