import React from 'react';
import { TableBody, TableHead, ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table } from './SizeGuideTable.styles';

import { TableRow } from './TableRow';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Larsseit',
  },
});

export default function SizeGuideTable({ data }) {
  return (
    <ThemeProvider theme={theme}>
      <Table>
        <TableHead>
          <TableRow rowData={data[0]} isHeader />
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return <TableRow rowData={row} isHeader={false} key={index} />;
          })}
        </TableBody>
      </Table>
    </ThemeProvider>
  );
}

SizeGuideTable.propTypes = {
  data: PropTypes.array.isRequired,
};
