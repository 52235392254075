import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { unescape } from 'he';
import { countryCurrencyData } from '@bgo-ui/common/components/CountrySelector/CountryChooser/countryCurrencyData';
import { overridenCountryCurrencyData } from '@bgo-ui/common/components/CountrySelector/CountryChooser/overridenCountryCurrencyData';
import { UNITED_STATES } from 'storefront/components/constants';
import { normalize } from './utilities-url';
import { isCountry } from './utilities-country';

const defaultSeparator = '-';

export function getTitle(
  prefix = '',
  separator = defaultSeparator,
  isIndexableSeo,
) {
  if (isIndexableSeo) {
    return prefix;
  }
  return `${prefix.trim()} ${separator} Bergdorf Goodman`.trim();
}

export function getMetadataTitle(prefix = '', separator = '|', isIndexableSeo) {
  if (isIndexableSeo) {
    return prefix;
  }
  return `Bergdorf Goodman ${separator} ${prefix.trim()}`.trim();
}

export function getPDPPageTitle(product = {}) {
  const productTitle = !product.isGroup
    ? getProductTitle(product)
    : `${getGroupPDPPageTitle(product)} & Matching Items`;
  return getTitle(productTitle);
}

function getDesignerName(product) {
  return unescape(get(product, 'designer.name', ''));
}

function getProductName(product) {
  return unescape(get(product, 'name', ''));
}

export function getProductTitle(product) {
  const designerName = getDesignerName(product);
  const productName = getProductName(product);
  return `${designerName} ${productName}`.trim();
}

export function getGroupPDPPageTitle(groupProduct) {
  let childProduct;
  const isEditorial = get(groupProduct, 'productFlags.isEditorial', false);
  if (!isEditorial) {
    childProduct = get(groupProduct, 'childProducts[0]', false);
  }
  const productName = getProductName(groupProduct);
  const designerName = childProduct ? getDesignerName(childProduct) : '';
  return `${designerName} ${productName}`.trim();
}

export function getMetaDescriptionGroupPDP(groupProduct) {
  const title = getGroupPDPPageTitle(groupProduct);
  return `Shop ${title} at Bergdorf Goodman. Find the latest luxury fashions from top designers.`;
}

export function getMetaDescriptionPDP(product) {
  const designerName = getDesignerName(product);
  const productName = getProductName(product);
  const content = `${designerName} ${productName}`.trim();
  return `Shop ${content} at Bergdorf Goodman. Find the latest luxury fashions from top designers.`;
}

export function getMetaInfoPDP(product) {
  return get(product, 'metadata.metaInfo', '');
}

export function getCanonicalUrlPDP(product) {
  const url = get(product, 'details.canonicalUrl', '');
  return normalize(url);
}

export function getMetaTitle(metaObject) {
  return getMetadataTitle(get(metaObject, 'metaTitle', ''));
}

export function getMetaDescription(metaObject) {
  return get(metaObject, 'metaDescription', '').trim();
}

export function getMetaKeywords(metaObject) {
  return get(metaObject, 'metaKeywords', '').trim();
}

export function getMetaTags(metaObject) {
  return get(metaObject, 'metaTags', []);
}

export function getRelLinks(metaObject) {
  return get(metaObject, 'relLinks', []);
}

export function normalizeCanonicalUrl(canonicalUrl) {
  const url = canonicalUrl && canonicalUrl.trim();
  return normalize(url);
}

export function isMobile() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(max-width: 767px)').matches
  );
}

export function isIOSDevice() {
  return typeof navigator !== 'undefined' && /iPhone/.test(navigator.userAgent);
}

export function isIphoneNonSafari() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const isIphone = /(iPhone)/gi.test(navigator.userAgent);
  const isOtherBrowser =
    !/Safari/.test(navigator.userAgent) ||
    /CriOS|FxiOS|EdgiOS|DuckDuckGo|OPiOS/.test(navigator.userAgent);

  return isIphone && isOtherBrowser;
}

export function isMediumView() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(max-width: 924px)').matches
  );
}

export function isDesktop() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(min-width: 1201px)').matches
  );
}

export function isIpadPro() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(max-width: 1024px)').matches
  );
}

export function redirect(url) {
  window.location.href = url;
}

export const getLocaleUrl = country => {
  const countryDetail = find(countryCurrencyData, { countryCode: country });
  return countryDetail ? countryDetail.url : '';
};

export function findAndReplaceLocaleContextInHref(
  locationPathName,
  localeContextPath,
  countryCode,
  localeUrl,
) {
  if (isCountry(countryCode, UNITED_STATES)) {
    return locationPathName.replace(localeContextPath, '');
  }
  return localeUrl + locationPathName.replace(localeContextPath, '');
}

export const getCurrencyCode = country => {
  const countryDetail =
    find(overridenCountryCurrencyData, { countryCode: country }) ||
    find(countryCurrencyData, { countryCode: country });
  return countryDetail ? countryDetail.currencyCode : '';
};

export const isTouchDevice = () => {
  if (typeof window !== 'undefined') {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
  }
  return false;
};

export const stripHtml = html => {
  if (html !== undefined) {
    return html.replace(/<[^>]+>/g, '');
  }
  return html;
};

export const stripEncodedHtml = html => {
  if (html !== undefined) {
    return html.replace(/%3C[^%3E]+%3E/g, '');
  }
  return html;
};

export const escapeObject = data => {
  if (!isEmpty(data)) {
    const clonedData = { ...data };
    Object.keys(clonedData).map(key => {
      const value = clonedData[key];
      delete clonedData[key];
      clonedData[stripHtml(key)] = stripHtml(value);
      return false;
    });
    return clonedData;
  }
  return data;
};

export const updateFilterTypeWithDisplayName = (state, applicableFilters) => {
  const { filterTypes = [] } = state;
  if (filterTypes.length && applicableFilters) {
    const updatedFilterType = filterTypes.map(filterType => {
      return get(
        applicableFilters.filter(item => item.filterKey === filterType)[0],
        'displayText',
        filterType,
      );
    });
    state.filterTypes = updatedFilterType;
  }
  return state;
};

export const enableIndexSearch = page => {
  const filterOptions = page?.location?.query?.filterOptions;
  const selectedFilters = isEmpty(filterOptions)
    ? {}
    : JSON.parse(filterOptions);
  delete selectedFilters['In Store'];
  const filterOptionStatus =
    Object.values(selectedFilters)?.filter(item => item && item.length > 0)
      ?.length > 0;
  return (
    page?.location?.pathname?.split('/')[1] === 'keyword' && !filterOptionStatus
  );
};
