import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import {
  getABTestAssignments,
  NEW_MOBILE_NAV_MBOX_ID,
  AEM_ABTEST_USERGROUP_MBOX_ID,
  TL389,
  getOptABTestAssignments,
} from 'shared/actions/actions-page';
import AppLogin from './app-login';
import { isNewMyAccountEnabled } from '../myaccount/utilities/is-new-myaccount';
import AppNewLogin from './myaccount/login/app-new-login';

class AppLoginWrapper extends Component {
  constructor(...args) {
    super(...args);
    this.state = { callAbTestService: true };
  }

  UNSAFE_componentWillMount() {
    const mBoxIds = [];

    this.props.newMobileNavToggle && mBoxIds.push(NEW_MOBILE_NAV_MBOX_ID);
    this.props.abtestAemContentToggle &&
      mBoxIds.push(AEM_ABTEST_USERGROUP_MBOX_ID);

    if (mBoxIds.length > 0) {
      if (shouldLoad(this.props.abTestApi)) {
        this.props.getABTestAssignments(mBoxIds.join(','), true);
      }
    } else {
      this.setState({ callAbTestService: false });
    }
    const opttIds = [];
    opttIds.push(TL389);
    if (opttIds.length > 0) {
      if (shouldLoad(this.props.abTestOptApi)) {
        this.props.getOptABTestAssignments(opttIds.join(','));
      }
    }
  }

  render() {
    const shouldContinue =
      !this.state.callAbTestService ||
      (this.state.callAbTestService &&
        (this.props.abTestApi.resolved || this.props.abTestApi.rejected));
    const render = this.props.isNewMyAccountEnabled ? (
      <AppNewLogin {...this.props} />
    ) : (
      <AppLogin {...this.props} />
    );
    return <Fragment>{shouldContinue ? render : null}</Fragment>;
  }
}

const mapStateToProps = state => ({
  newMobileNavToggle: state.toggles.NEW_MOBILE_NAV,
  abtestAemContentToggle: state.toggles.ABTEST_AEM_CONTENT_TARGETING,
  abTestApi: state.api.abtest || {},
  abTestOptApi: state.api.abtestopt || {},
  isNewMyAccountEnabled: isNewMyAccountEnabled(state),
});
const mapDispatchToProps = {
  getABTestAssignments,
  getOptABTestAssignments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppLoginWrapper);
