import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Divider } from '@material-ui/core';

import BottomSheet from '../BottomSheet/BottomSheet.js';
import SizeGuideBottomSheet from '../SizeGuide/SizeGuideBottomSheet.js';
import {
  SelectBox,
  Placeholder,
  Arrow,
  PlaceholderText,
  BottomSheetContainer,
  ContentWrapper,
  PullHandle,
  SizeText,
  FitGuidance,
  Option,
  SizeGuideText,
  BottomSheetGridContainer,
  StyledGridContainer,
} from './SizeBottomSheet.styles.js';

const SizeBottomSheet = ({ product, sizeGuideResponse, ...props }) => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isSizeGuideContent, setIsSizeGuideContent] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const [selectedOption, setSelectedOption] = useState();

  const touchStartRef = useRef(null);
  const touchCurrentRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);

  const { options, optionSettings, isEligibleFitguidance } = props;

  const sizeGuideName = product?.designer?.name
    ? `${product?.designer?.name} Size Guide`
    : 'Size Guide';

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
    setIsSizeGuideContent(false);
  };

  const handleSizeSelector = () => {
    setIsSizeGuideContent(false);
  };

  // Pull down close
  const handleTouchStart = e => {
    touchStartRef.current = e?.touches?.[0]?.clientY;
  };

  const handleTouchMove = e => {
    touchCurrentRef.current = e?.touches?.[0]?.clientY;
  };

  const handleTouchEnd = () => {
    if (touchStartRef?.current && touchCurrentRef?.current) {
      const swipeDistance = touchCurrentRef?.current - touchStartRef?.current;
      if (swipeDistance > 50) {
        setIsBottomSheetOpen();
        setIsSizeGuideContent(false);
      }
    }
    touchStartRef.current = null;
    touchCurrentRef.current = null;
  };

  function getLowestLevelKey(hierarchyElement) {
    const [lastKey] = Object?.keys(hierarchyElement)
      .filter(key => key?.includes('level'))
      .sort((a, b) => b?.localeCompare(a));

    return lastKey;
  }

  function getCategoryName(product) {
    const { hierarchy = [] } = product;
    const [firstHierarchy] = hierarchy;

    if (!firstHierarchy) {
      return '';
    }

    const lowestLevelKey = getLowestLevelKey(firstHierarchy);

    if (!lowestLevelKey) {
      return '';
    }

    return `${firstHierarchy[lowestLevelKey]}`;
  }

  function getValue(value, data, defaultValue) {
    let optionValue = defaultValue;
    switch (value) {
      case 'index_index':
        optionValue = defaultValue;
        break;

      case 'index_value':
        optionValue = typeof data !== 'object' ? data : defaultValue;
        break;

      default:
        optionValue =
          data && data[value] && typeof data[value] !== 'object'
            ? data[value]
            : defaultValue;
        break;
    }
    return optionValue;
  }

  let selectedKey = props.selectedValue // eslint-disable-line no-nested-ternary
    ? props?.selectedValue
    : props?.selectedIndex > -1 && optionSettings && optionSettings?.label
    ? getValue(
        props?.disableCustomDropDown
          ? optionSettings?.value
          : optionSettings?.label,
        options[(props?.selectedIndex)],
        props?.selectedIndex,
      )
    : props?.selectedIndex;

  selectedKey =
    selectedKey < 0 && optionSettings && optionSettings.selectLabel
      ? optionSettings.selectLabel
      : selectedKey;

  if (options.length === 0) return false;

  selectedKey =
    selectedKey < 0 && optionSettings && optionSettings.selectLabel
      ? optionSettings.selectLabel
      : selectedKey;

  const handleSelect = (name, label) => {
    setIsBottomSheetOpen(false);
    props.onClick(name, label);
  };

  const handSelector = () => {
    if (options?.length >= 2) {
      if (window) {
        setScrollY(window?.scrollY);
      }
      setIsBottomSheetOpen(true);
    }
  };

  useEffect(() => {
    const optionSelected = options?.find((obj, index) => {
      return index === selectedKey;
    });
    setSelectedOption(optionSelected?.name);
  }, [selectedKey]);

  const isProductEligible = Object.keys(isEligibleFitguidance).length > 0;

  const optionList = options.map((optionValue, index) => {
    const label = optionSettings && optionSettings.label;
    const optionLabel = label ? getValue(label, optionValue, index) : index;
    const value = optionSettings && optionSettings.value;
    const optionVal = value ? getValue(value, optionValue, index) : index;
    const { mouseOver, mouseOut } = props;
    return (
      <>
        <Divider />
        <Option
          onMouseEnter={() => setFocusedOptionIndex(index)}
          className={classnames({ 'disabled-select': optionValue.inactive })}
          disabled={optionValue.inactive}
          value={optionVal}
          key={index}
          style={{
            backgroundColor: focusedOptionIndex === index ? '#E9ECFB' : 'white',
          }}
          onClick={() => handleSelect(index, props.label)}
        >
          {optionLabel}
          {'  '}
          {optionValue.inactive && props.disableText ? props.disableText : ''}
        </Option>
      </>
    );
  });

  return (
    <>
      <SelectBox onClick={handSelector}>
        <Placeholder>
          {!selectedOption ? 'Select Size' : selectedOption}
        </Placeholder>
        <Arrow />
      </SelectBox>
      <BottomSheet
        isBottomSheetOpen={isBottomSheetOpen}
        setIsBottomSheetOpen={handleBottomSheetClose}
        scrollY={scrollY}
        child={
          isSizeGuideContent ? (
            <SizeGuideBottomSheet
              title={
                getCategoryName(product) &&
                `${getCategoryName(product)} Size Guide`
              }
              secondaryTitle={sizeGuideName && sizeGuideName}
              sizeGuides={sizeGuideResponse}
              onClose={handleBottomSheetClose}
              handleTouchStart={handleTouchStart}
              handleTouchMove={handleTouchMove}
              handleTouchEnd={handleTouchEnd}
              handleSizeSelector={handleSizeSelector}
            />
          ) : (
            <>
              <BottomSheetGridContainer
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <SizeText>SELECT SIZE</SizeText>
                {((sizeGuideResponse && sizeGuideResponse?.length !== 0) ||
                  isProductEligible) && (
                  <StyledGridContainer>
                    <div>
                      {isProductEligible && (
                        <FitGuidance>
                          Fit runs{' '}
                          <strong> {isEligibleFitguidance?.note}</strong>
                        </FitGuidance>
                      )}
                    </div>
                    <div>
                      {sizeGuideResponse && sizeGuideResponse?.length !== 0 && (
                        <SizeGuideText
                          onClick={() => setIsSizeGuideContent(true)}
                        >
                          Size Guide
                        </SizeGuideText>
                      )}
                    </div>
                  </StyledGridContainer>
                )}
              </BottomSheetGridContainer>

              <ContentWrapper isFitguidance={false}>
                {optionList}
              </ContentWrapper>
            </>
          )
        }
      />
    </>
  );
};

export default SizeBottomSheet;
