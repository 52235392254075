import React, { useEffect, useRef } from 'react';
import { Drawer } from '@material-ui/core';
import { BottomSheetContainer, PullHandle } from './BottomSheet.styles';

export default function BottomSheet({
  isBottomSheetOpen,
  setIsBottomSheetOpen,
  scrollY,
  child,
}) {
  const touchStartRef = useRef(null);
  const touchCurrentRef = useRef(null);

  // Prevent background scroll
  useEffect(() => {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
      return () => {};
    }

    const { body, documentElement } = document;
    if (isBottomSheetOpen) {
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      body.style.width = '100%';
    } else {
      body.style.position = '';
      body.style.top = ``;
      body.style.width = '';
      window.scrollTo(0, scrollY);
    }

    return () => {
      body.style.position = '';
      body.style.top = ``;
      body.style.width = '';
    };
  }, [isBottomSheetOpen]);

  // Pull down close
  const handleTouchStart = e => {
    touchStartRef.current = e?.touches?.[0]?.clientY;
  };

  const handleTouchMove = e => {
    touchCurrentRef.current = e?.touches?.[0]?.clientY;
  };

  const handleTouchEnd = () => {
    if (touchStartRef?.current && touchCurrentRef?.current) {
      const swipeDistance = touchCurrentRef?.current - touchStartRef?.current;
      if (swipeDistance > 50) {
        setIsBottomSheetOpen();
      }
    }
    touchStartRef.current = null;
    touchCurrentRef.current = null;
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={isBottomSheetOpen}
        onClose={setIsBottomSheetOpen}
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      >
        <BottomSheetContainer>
          <PullHandle
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          />
          {child}
        </BottomSheetContainer>
      </Drawer>
    </>
  );
}
