import styled, { css } from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core/styles';

export const MaterialDrawer = withStyles({
  paper: {
    width: 375,
    padding: '20px 30px',
    boxSizing: 'border-box',
    fontSize: 16,
    lineHeight: '24px',

    '@media (max-width: 767px)': {
      width: '90%',
    },
  },
})(SwipeableDrawer);

export const DrawerHeaderWrapper = styled.div`
  font-family: 'neuzeit-grotesk', futura-pt, Century Gothic, Roboto, sans-serif;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  align-self: flex-end;
  border: none;
  background: none;
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
`;

export const DrawerContentWrapper = styled.div`
  font-family: 'neuzeit-grotesk', futura-pt, Century Gothic, Roboto, sans-serif;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const DrawerContainer = styled.div`
  margin-top: 13px;
  margin-left: 1px;
`;

export const DrawerTitle = styled.h2`
  font-family: 'Larsseit';
  text-transform: uppercase;
  font-weight: 400;
  line-height: 30px;
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 18px;
`;

export const SecondaryTitle = styled.p`
  font-family: 'Larsseit';
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
`;
