import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  DrawerHeaderWrapper,
  CloseButton,
  DrawerContentWrapper,
  DrawerTitle,
  MaterialDrawer,
  DrawerContainer,
  SecondaryTitle,
} from './Drawer.styles';

const getAdditionalProps = drawerComponent => {
  if (drawerComponent === MaterialDrawer) {
    return {
      onOpen: () => {},
      disableSwipeToOpen: true,
    };
  }

  return {};
};

const Drawer = ({
  isDrawerOpened,
  onClose,
  anchor = 'right',
  title,
  secondaryTitle,
  drawerComponent: DrawerComponent = MaterialDrawer,
  children,
  ...props
}) => {
  return (
    <DrawerComponent
      anchor={anchor}
      open={isDrawerOpened}
      onClose={onClose}
      {...getAdditionalProps(DrawerComponent)}
      {...props}
    >
      <header>
        <DrawerHeaderWrapper>
          <CloseButton onClick={onClose} tabIndex="0" aria-label="Close button">
            <CloseIcon />
          </CloseButton>
          <DrawerContainer>
            {secondaryTitle && (
              <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
            )}
            <DrawerTitle>{title}</DrawerTitle>
          </DrawerContainer>
        </DrawerHeaderWrapper>
      </header>
      <DrawerContentWrapper>{children}</DrawerContentWrapper>
    </DrawerComponent>
  );
};

export default Drawer;
