import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compact from 'lodash/compact';
import get from 'lodash/get';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { TEMPLATE_A } from 'bgo-common/components/constants';
import { getPDPImageMediumUrl } from 'bgo-common/components/Util/product-cloudinary';
import {
  openProductImageZoom,
  zoomProductImage,
  zoomProductImageQl,
  openTemplateDZoomModal,
} from 'pdp/components/ProductPage/actions';
import Image from 'pdp/components/ProductPage/components/Image/image';
import Video from 'pdp/components/ProductPage/components/Video/video';
import ZoomImage from 'pdp/components/ProductPage/components/ProductMedia/components/ZoomImage/zoomImage';
import { generatePdpAlt } from 'pdp/utilities/utilities-alt';
import wishlistCtaActiveImg from 'bgo-common/assets/images/wishlistCtaActive.svg';
import SaveToWishlistAsIcon from '../../../../../wishlist/components/WishlistPage/components/Wishlist/saveToWishlistAsIcon';
import getSelectedSku from '../../selectors/getSelectedSku';
import getColorSkus from '../../selectors/getColorSkus';
import { getColorOptions, getSizeOptions } from '../../selectors/getOptions';
import getSizeSkus from '../../selectors/getSizeSkus';
import getSelectedColorIndex from '../../selectors/getSelectedColorIndex';
import { removeWishlist } from '../../../../../wishlist/components/WishlistPage/actions';

import './productMedia.scss';

SwiperCore.use([Navigation, Pagination]);
const ProductMedia = props => {
  const {
    brand = '',
    firstGroupProduct,
    isCloudinary,
    isGroup,
    isMediaMergeLogicDisabled,
    name,
    media,
    openProductImageZoom,
    productId,
    device: { isDesktop, isTablet, isMobile },
    openTemplateDZoomModal,
    handleAnalyticsEvents,
    isFromQL = false,
    zoomPdpToggle,
    videoInCarouselToggle,
    updatePdpLayout,
    hideFromEditWishlist = true,
    showAddToWishlist,
    isDomestic,
    product,
    removeWishlist,
    saveToWishlistIconToggle,
    updateZoomState,
  } = props;
  const [onSlideLoad, setOnSlideLoad] = useState(false);

  const groupMedia =
    isGroup && productId === firstGroupProduct ? props.groupMedia : [];

  const { mergedMedia, optionMedia } = media;

  let images;

  useEffect(() => {
    setOnSlideLoad(true);
  }, []);
  const compactImages = media => {
    if (isMediaMergeLogicDisabled) {
      images = compact([
        media && media.main,
        ...values((media && media.alternate) || []),
        ...groupMedia,
      ]).filter(media => media.dynamic); // Filters images by availability of dynamic media.
    } else {
      images = compact([
        media && media.main,
        ...values((media && media.alternate) || []),
        ...groupMedia,
      ]);
    }
  };

  if (isMediaMergeLogicDisabled) {
    if (isEmpty(optionMedia)) {
      compactImages(mergedMedia);
    } else if (optionMedia.main.dynamic) {
      compactImages(optionMedia);
    } else {
      images = [{ dynamic: '' }];
    }
  } else {
    compactImages(media);
  }

  const videos = [];
  const video = get(media, 'video', {});
  if (video?.video?.url) {
    videos.push(video);
  }
  const additionalVideos = get(media, 'additionalVideos', []);
  if (additionalVideos?.length > 0) {
    additionalVideos.forEach(video => {
      videos.push(video);
    });
  }

  const handleZoom = (images, index) => {
    updateZoomState();
    if (isDesktop || isTablet) {
      openProductImageZoom(images, index, isCloudinary, zoomPdpToggle);
    } else if (isMobile) {
      openTemplateDZoomModal(images, index, isCloudinary, true, zoomPdpToggle);
    }
  };
  const singleImage = images.length === 1;
  const slidesPerViewUpdate = isMobile || singleImage ? 1 : 1.5;

  const getSelectedSkuinComp = () => {
    const colorOptions = getColorOptions(product);
    const sizeOptions = getSizeOptions(product);
    const selectedColorIndex = getSelectedColorIndex(product);
    let selectedSizeIndex = get(product, 'options.selectedSizeIndex', -1);

    if (selectedSizeIndex === -1) {
      selectedSizeIndex = sizeOptions?.values.findIndex(
        item => item?.inactive === false,
      );
    }

    const colorSkus = getColorSkus(product, selectedSizeIndex);
    const sizeSkus = getSizeSkus(product, selectedColorIndex);
    const ag = getSelectedSku(
      { colorSkus, sizeSkus },
      { colorOptions, sizeOptions },
      { selectedColorIndex, selectedSizeIndex },
    );

    return ag;
  };

  const isSkuAddedToWishlist = selectedSku => {
    const { wishlist } = props;

    if (
      wishlist &&
      wishlist.items &&
      wishlist.items.length > 0 &&
      selectedSku
    ) {
      for (let i = 0; i < wishlist.items.length; i++) {
        if (wishlist.items[i].skuId === selectedSku.id) {
          return true;
        }
      }
    }
    return false;
  };

  const selectedSKULocal = getSelectedSkuinComp();

  const newCond =
    isDomestic &&
    showAddToWishlist &&
    hideFromEditWishlist &&
    !product?.isZeroDollarProduct &&
    saveToWishlistIconToggle;

  return (
    <div
      className={`product-media product-media-d item-count-${images.length}`}
    >
      <Swiper
        navigation={!singleImage && (isDesktop || isTablet)}
        className={`swiper-carousel-buttons ${
          updatePdpLayout && singleImage ? 'single-image' : ''
        }`}
        allowTouchMove={!singleImage}
        spaceBetween={updatePdpLayout ? 10 : 30}
        pagination={
          !singleImage && {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: (index, className) => {
              const videoCls = index + 1 > images?.length ? 'video-bullet' : '';
              // eslint-disable-next-line prefer-template
              return '<span class="' + videoCls + ' ' + className + '"></span>';
            },
          }
        }
        onSlideChange={() =>
          !isFromQL &&
          onSlideLoad &&
          handleAnalyticsEvents({
            product_interaction: 'alternate image',
          })}
        slidesPerView={updatePdpLayout ? slidesPerViewUpdate : 1}
        loop
        loopAdditionalSlides={images.length}
      >
        {images &&
          images.map((image, index) => (
            <SwiperSlide>
              <ZoomImage productId={productId} isCloudinary={isCloudinary} />
              <Image
                alt={generatePdpAlt(brand, name, index, images.length)}
                src={getPDPImageMediumUrl(image, isCloudinary, TEMPLATE_A)}
                isCloudinary={isCloudinary}
                onClick={() => handleZoom(images, index)}
                onKeyPress={() => handleZoom(images, index)}
              />
            </SwiperSlide>
          ))}

        {videoInCarouselToggle &&
          videos?.length > 0 &&
          videos.map((video, index) => (
            <SwiperSlide>
              {({ isActive }) => (
                <>
                  <Video
                    videoSrc={video?.video?.url}
                    posterSrc={video?.thumbnail?.url}
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                    id={index}
                    isActive={isActive}
                  />
                </>
              )}
            </SwiperSlide>
          ))}
        {newCond &&
          (!isSkuAddedToWishlist(selectedSKULocal) ? (
            <>
              <SaveToWishlistAsIcon
                selectedSku={selectedSKULocal}
                productId={product?.id}
                styleUpdate={images?.length === 1 ? 'right_zero' : ''}
              />
            </>
          ) : (
            <span
              className={`saveToWishlist wishlistCtaButton ${
                images?.length === 1 ? 'right_zero' : ''
              } ${isMobile ? 'mobileBtn' : ''}`}
              onClick={async () => {
                await removeWishlist(selectedSKULocal?.id);
              }}
            >
              <img
                alt="Wishlist Cta"
                aria-hidden="true"
                className="wishlist-cta-img "
                src={wishlistCtaActiveImg}
              />
            </span>
          ))}
      </Swiper>
      <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets" />
    </div>
  );
};

const mapStateToProps = state => {
  const isGroup = get(state, 'productCatalog.product.isGroup');
  const firstGroupProduct = get(
    state,
    'productCatalog.group.childProducts.productIds[0]',
  );
  const media = get(state, 'productCatalog.group.media', {});
  const groupMedia = compact([media.main, ...values(media.alternate || [])]);
  const device = get(state, 'device', {});
  const zoomPdpToggle = get(state, 'toggles.ZOOM_FN_PDP', false);
  const videoInCarouselToggle = get(state, 'toggles.USE_BG_VIDEO', false);

  return {
    isGroup,
    firstGroupProduct,
    groupMedia,
    device,
    zoomPdpToggle,
    videoInCarouselToggle,
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    showAddToWishlist: state.toggles.BG_PHASE_2_WISHLIST,
    wishlist: state?.wishlist?.wishlist,
    saveToWishlistIconToggle: state.toggles.PDP_WISHTLIST_CTA_AS_ICON,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  zoomProductImage: (...args) => dispatch(zoomProductImage(productId, ...args)),
  zoomProductImageQl: (...args) =>
    dispatch(zoomProductImageQl(productId, ...args)),
  openProductImageZoom: (...args) =>
    dispatch(openProductImageZoom(productId, ...args)),
  openTemplateDZoomModal: (...args) =>
    dispatch(openTemplateDZoomModal(productId, ...args)),
  removeWishlist: (...args) => dispatch(removeWishlist(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductMedia);
