import React from 'react';
import Drawer from '../Drawer/Drawer';
import {
  Drawer as DrawerComponent,
  Container,
  StoreContainer,
  Text,
  CallText,
} from './SizeGuideModal.styles';
import { SizesAndConversions } from '../SizeGuideConvertion/SizesAndConversions';

const NEED_US = `https://assistance.bergdorfgoodman.com/contact`;

export default function SizeGuideModal({
  isOpen,
  onClose,
  title,
  secondaryTitle = '',
  sizeGuides,
  ...props
}) {
  return (
    <Drawer
      isDrawerOpened={isOpen}
      onClose={onClose}
      title={title}
      secondaryTitle={secondaryTitle}
      drawerComponent={DrawerComponent}
      {...props}
    >
      <Container>
        <SizesAndConversions sizeGuides={sizeGuides} />
      </Container>

      <Container>
        <Text>Need more information?</Text>
      </Container>

      <StoreContainer>
        <a href={NEED_US} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
        <CallText>&nbsp;or&nbsp;call us&nbsp;</CallText>
        <a href="tel:+1.888.774.2424" decoration="none" weight="bold">
          1.888.774.2424
        </a>
      </StoreContainer>
    </Drawer>
  );
}
