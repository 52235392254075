import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { TabPanel } from './TabPanel';
import SizeGuideTable from './SizeGuideTable';
import {
  Tab,
  Tabs,
  OverflowWrapper,
  Metric,
  MetricsWrapper,
} from './SizesAndConversions.styles';

const METRICS = ['inches', 'cm'];

function createTableHeaders(
  sizeGuidesRow,
  availableSizeValueRows,
  availableSizeLabelRows,
) {
  const row = {};
  if (availableSizeLabelRows?.length) {
    row.alpha = { label: 'Alpha', display: sizeGuidesRow?.sizeLabel };
  }
  if (availableSizeValueRows?.length) {
    row.us = { label: 'US', display: sizeGuidesRow?.sizeValue };
  }
  return row;
}

function checkUsSizing(sizeGuidesRows) {
  return sizeGuidesRows?.filter(row => !isEmpty(row?.sizeValue));
}

function checkAlphaSizing(sizeGuidesRows) {
  return sizeGuidesRows?.filter(row => !isEmpty(row?.sizeLabel));
}

export function createMeasurementsTableData(unit, sizeGuidesRows) {
  const usSizing = checkUsSizing(sizeGuidesRows);
  const alphaSizing = checkAlphaSizing(sizeGuidesRows);

  return sizeGuidesRows.map(sizeGuidesRow => {
    const row = createTableHeaders(sizeGuidesRow, usSizing, alphaSizing);

    if (isEmpty(sizeGuidesRow.measurements)) return {};

    Object.entries(sizeGuidesRow.measurements[unit]).forEach(([key, val]) => {
      const regExp = /[A-Z]/;
      let tempVal = val;

      if (val?.value?.includes('-')) {
        const splitVal = val.value.split('-').map(v => v.trim());
        const [val1, val2] = splitVal;
        const temp1 = regExp.test(val1) ? val1 : Math.round(val1 * 2) / 2;
        const temp2 = regExp.test(val2) ? val2 : Math.round(val2 * 2) / 2;
        const temp3 = /^[a-zA-Z]+$/.test(temp2) ? temp2 : `${temp2}`;
        const conVal =
          unit === 'inches' ? `${temp1}" - ${temp3}"` : `${temp1} - ${temp3}`;

        tempVal = { ...val, display: conVal };
      } else {
        const val3 = regExp.test(val.value)
          ? val.value
          : Math.round(val.value * 2) / 2;
        tempVal = {
          ...val,
          display: unit === 'inches' ? `${val3}"` : `${val3}`,
        };
      }

      row[key] = tempVal;
    });

    return row;
  });
}

function createConversionsTableData(sizeGuidesRows) {
  const usSizing = checkUsSizing(sizeGuidesRows);
  const alphaSizing = checkAlphaSizing(sizeGuidesRows);

  return sizeGuidesRows.map(sizeGuidesRow => {
    const row = createTableHeaders(sizeGuidesRow, usSizing, alphaSizing);

    if (isEmpty(sizeGuidesRow.i18nConversions)) {
      return {};
    }

    Object.entries(sizeGuidesRow.i18nConversions).forEach(([key, value]) => {
      row[key] = { label: key, display: value };
    });

    return row;
  });
}

function isDataEmpty(data) {
  return data.every(item => Object.keys(item).length === 0);
}

export function SizesAndConversions({ sizeGuides }) {
  const [tabValue, setTabValue] = useState(0);
  const [metricSelected, setMetricSelected] = useState('inches');
  const [measurementsData, setMeasurementsData] = useState([]);
  const [conversionsData, setConversionsData] = useState([]);
  const [conversionsTabIndex, setConversionsTabIndex] = useState(0);

  useEffect(() => {
    if (!sizeGuides.length) return;

    setMeasurementsData(createMeasurementsTableData('inches', sizeGuides));
    setConversionsData(createConversionsTableData(sizeGuides));
  }, [sizeGuides]);

  useEffect(() => {
    setConversionsTabIndex(isDataEmpty(measurementsData) ? 0 : 1);
  }, [measurementsData]);

  if (!sizeGuides.length) {
    return null;
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = index => ({
    id: `size-guide-tab-${index}`,
    'aria-controls': `size-guide-tabpanel-${index}`,
  });

  const handleUnitClick = event => {
    setMeasurementsData(
      createMeasurementsTableData(event.target.value, sizeGuides),
    );
    setMetricSelected(event.target.value);
  };

  const renderMetric = (metric, index) => (
    <Metric
      key={index}
      role="button"
      tabIndex="0"
      value={metric}
      onClick={e => handleUnitClick(e)}
      selected={metricSelected === metric}
      disableRipple
    >
      {metric}
    </Metric>
  );

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="size-guide-tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#000000',
            zIndex: 1,
            padding: 0,
            position: 'absolute',
            height: '2px',
            bottom: 0,
            top: 'auto',
            maxWidth: 'none',
            visibility: 'visible',
            border: 'none',
          },
        }}
      >
        {!isDataEmpty(measurementsData) && (
          <Tab label="Measurements" {...a11yProps(0)} disableRipple />
        )}
        {!isDataEmpty(conversionsData) && (
          <Tab
            label="Conversions"
            {...a11yProps(conversionsTabIndex)}
            disableRipple
          />
        )}
      </Tabs>

      {!isDataEmpty(measurementsData) && (
        <TabPanel
          value={tabValue}
          index={0}
          label="size-guide-tabpanel"
          labelledBy="size-guide-tab"
        >
          <MetricsWrapper>
            {METRICS.map((metric, index) => renderMetric(metric, index))}
          </MetricsWrapper>
          <OverflowWrapper className="no-margin">
            <SizeGuideTable data={measurementsData} />
          </OverflowWrapper>
        </TabPanel>
      )}

      {!isDataEmpty(conversionsData) && (
        <TabPanel
          value={tabValue}
          index={conversionsTabIndex}
          label="size-guide-tabpanel"
          labelledBy="size-guide-tab"
        >
          <OverflowWrapper>
            <SizeGuideTable data={conversionsData} />
          </OverflowWrapper>
        </TabPanel>
      )}
    </>
  );
}

SizesAndConversions.propTypes = {
  sizeGuides: PropTypes.array.isRequired,
};
