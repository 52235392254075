import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  increaseQuantity,
  decreaseQuantity,
  updateQuantity,
} from 'pdp/components/ProductPage/actions';
import CustomDropDownComponent from 'pdp/components/ProductPage/optimizationcomponents/CustomDropDownComponent/customDropDownComponent';
import './productQuantity.scss';

class ProductQuantity extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(index) {
    const { quantity } = this.props;
    const enteredQuantity = index || 1;
    if (quantity !== enteredQuantity || !isNaN(enteredQuantity)) {
      this.props.updateQuantity(enteredQuantity);
    }
    this.props.updateQuantityState();
  }

  render() {
    const { id, quantity } = this.props.product || {};

    const selectedSku = this.props.selectedSku;
    let itemArr = [];
    const maxQuantity = 10;
    if (selectedSku && selectedSku.inStock && selectedSku.stockLevel) {
      const availableItems =
        selectedSku.stockLevel > 10 ? maxQuantity : selectedSku.stockLevel;
      for (let i = 1; i <= availableItems; i++) {
        itemArr.push(i);
      }
    } else if (selectedSku && !selectedSku.inStock && selectedSku.backOrder) {
      for (let i = 1; i <= 10; i++) {
        itemArr.push(i);
      }
    } else {
      itemArr = [1];
    }

    return (
      <span className="product-quantity">
        <CustomDropDownComponent
          productId={id}
          options={itemArr}
          selectedIndex={0}
          optionType="quantity"
          onClick={this.handleChange}
          selectedValue={quantity}
          isGroupPDP={this.props.isGroupPDP}
          disableCustomDropDown={this.props.disableCustomDropDown}
          optionSettings={{
            label: 'index_value',
            value: 'index_value',
          }}
        />
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch, { product: { id: productId } = {} }) => ({
  increaseQuantity: (...args) => dispatch(increaseQuantity(productId, ...args)),
  decreaseQuantity: (...args) => dispatch(decreaseQuantity(productId, ...args)),
  updateQuantity: (...args) => dispatch(updateQuantity(productId, ...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ProductQuantity);
