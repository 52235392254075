import React from 'react';
import CustomDropDownComponent from 'pdp/components/ProductPage/optimizationcomponents/CustomDropDownComponent/customDropDownComponent';
import { updateQuantity } from 'pdp/components/ProductPage/actions';
import { connect } from 'react-redux';
import { EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import get from 'lodash/get';
import SizeBottomSheet from '../SizeBottomSheet/SizeBottomSheet';

class ProductSize extends React.Component {
  componentDidMount() {
    if (this.props.options.length === 1 && this.props.selectedIndex !== 0) {
      this.props.onClick(0, this.props.label);
    }
  }

  onClick = (selectedIndex, label) => {
    this.props.updateSizeState();
    this.props.updateQuantity(1);
    this.props.onClick(selectedIndex, label);
  };

  render() {
    const { availableSkus, options, isEligibleFitguidance } = this.props;

    const isDisabled = index => {
      if (!availableSkus || !availableSkus.length) {
        return false;
      }
      if (!availableSkus[index]) {
        return true;
      }
      return !get(availableSkus[index], 'sellable');
    };

    const onlyXLeft = quantity => `Only ${quantity} Left`;
    const shouldShowOnlyXLeft = (sku, threshold) =>
      sku?.stockLevel &&
      sku?.stockLevel <= threshold &&
      !sku?.dropShip &&
      !sku?.backOrder &&
      !sku?.preOrder;

    const updateStockStatusMessageForXLeft = (
      sku,
      preciousJewelryItem,
      isChanel,
      threshold,
    ) => {
      if (
        shouldShowOnlyXLeft(sku, threshold) &&
        !preciousJewelryItem &&
        !isChanel
      ) {
        return onlyXLeft(sku?.stockLevel);
      }
      return null;
    };

    const FilteredOptions = options.map((value, index) => {
      if (this.props?.disableCustomDropDown && this.props?.bgSizeButton) {
        const selectedSku = this.props?.product?.skus?.find(
          e => e?.size?.key === value?.key,
        );
        const onlyXLeftMessage = updateStockStatusMessageForXLeft(
          selectedSku,
          this.props?.preciousJewelryItem,
          this.props?.isChanel,
          this.props?.onlyXLeftThreshold,
        );
        if (onlyXLeftMessage && value?.inactive === false) {
          const upperCaseMessage = String(onlyXLeftMessage).toUpperCase();
          if (!options[index]['name']?.includes(upperCaseMessage)) {
            options[index]['name'] = `${value?.name} - ${upperCaseMessage}`;
          }
        }
      }
      options[index]['inactive'] = isDisabled(index);
      return options[index];
    });

    const sizeDropdown =
      options.length === 1 ? (
        <EyeBrow1> {options[0]['name']} </EyeBrow1>
      ) : (
        <CustomDropDownComponent
          {...this.props}
          disableText="(Out Of Stock)"
          options={FilteredOptions}
          onClick={this.onClick}
          isEligibleFitguidance={isEligibleFitguidance}
        />
      );

    const sizeBottomSheet = (
      <SizeBottomSheet
        {...this.props}
        disableText="- (Out Of Stock)"
        options={FilteredOptions}
        onClick={this.onClick}
        product={this.props?.product}
        sizeGuideResponse={this.props?.sizeGuideResponse}
        isEligibleFitguidance={isEligibleFitguidance}
      />
    );

    return this.props?.disableCustomDropDown && this.props?.bgSizeButton
      ? sizeBottomSheet
      : sizeDropdown;
  }
}

const mapDispatchToProps = (dispatch, { productId }) => ({
  updateQuantity: (...args) => dispatch(updateQuantity(productId, ...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ProductSize);
