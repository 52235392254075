import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  Table as MaterialTable,
  TableCell as MaterialTableCell,
  TableRow as MaterialTableRow,
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';

export const Table = withStyles({
  root: {
    marginBottom: 0,
  },
})(MaterialTable);

export const TableCell = withStyles({
  root: {
    fontSize: '16px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #000000',
    borderRight: '1px solid #000000',
    padding: '15px',
    boxSizing: 'border-box',
    fontFamily: 'Larsseit',
    '&:last-child': {
      borderRight: 'none',
    },
  },

  head: {
    fontWeight: 700,
    '&:first-child': {
      position: 'sticky',
      left: 0,
      fontFamily: 'Larsseit',
      borderRight: 'none',
      '&::after': {
        content: '""',
        backgroundColor: '#000000',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        borderRight: '1px solid #000000',
      },
    },
  },
})(MaterialTableCell);

export const TableRowWrapper = withStyles({
  root: {
    backgroundColor: '#ffffff',

    '& .MuiTableCell-head': {
      backgroundColor: '#ffffff',
    },

    '& .MuiTableHead-root &': {
      backgroundColor: '#ebebec',
    },

    '&:nth-child(even)': {
      backgroundColor: '#ebebec',

      '& .MuiTableCell-head': {
        backgroundColor: '#ebebec',
      },
    },

    '& + &.MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
})(TableRow);
