import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { EyeBrow7 } from 'bgo-common/Styleguide/Typography';
import {
  showAddToBagError,
  clearError,
} from 'pdp/components/ProductPage/actions';
import get from 'lodash/get';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import { getColorOptions } from 'pdp/components/ProductPage/selectors/getOptions';
import getSelectedColorIndex from 'pdp/components/ProductPage/selectors/getSelectedColorIndex';

import { addWishlist } from '../../actions';
import {
  ERROR_SIZE_NOT_SELECTED,
  ERROR_PROD_OPTION_NOT_SELECTED,
  SAVE_TO_WISHLIST,
  ERROR_DATE_NOT_SELECTED,
  ATG_SIGN_IN_URL,
  DT_SIGN_IN_URL,
} from '../../../../constants';
import './wishlist.scss';

export class SaveToWishlist extends Component {
  constructor(props) {
    super(props);
    this.onClickSaveToWishlist = this.onClickSaveToWishlist.bind(this);
    this.validateSelectedProduct = this.validateSelectedProduct.bind(this);
  }

  onClickSaveToWishlist() {
    const { product, selectedSku } = this.props;
    this.props.clearError();
    const errorMessage = this.validateSelectedProduct(product, selectedSku);
    if (errorMessage) {
      this.props.showAddToBagError(errorMessage);
      return;
    }

    if (this.isAuthenticatedUser()) {
      this.props.addWishlist(product, selectedSku);
    } else {
      this.saveSkuAndRedirectToLogin();
    }
  }

  validateSelectedProduct(productState, selectedSku) {
    const { name = '' } = productState;
    let colorName = '';
    const selectedColorIndex = getSelectedColorIndex(productState);
    const selectedSizeIndex = get(
      productState,
      'options.selectedSizeIndex',
      -1,
    );
    let errMessage = '';
    if (!selectedSku) {
      if (selectedSizeIndex === -1) {
        const colorSkus = getColorOptions(productState);
        if (colorSkus && colorSkus.values.length > 0) {
          colorName = `, ${colorSkus.values[selectedColorIndex].name}`;
        }
        errMessage = `${ERROR_SIZE_NOT_SELECTED} ${name}${colorName}.`;
      } else {
        errMessage = `${ERROR_PROD_OPTION_NOT_SELECTED} ${name}${colorName}.`;
      }
    }
    if (productState.perishable && !productState.deliveryDate) {
      errMessage = ERROR_DATE_NOT_SELECTED;
    }
    return errMessage;
  }

  isAuthenticatedUser() {
    return (
      get(this.props, 'user.securityStatus', '').toLowerCase() ===
      'authenticated'
    );
  }

  saveSkuAndRedirectToLogin() {
    const {
      product,
      selectedSku,
      stlParentProductId,
      dtSignInToggle,
    } = this.props;
    const signInUrl = `${
      dtSignInToggle ? DT_SIGN_IN_URL : ATG_SIGN_IN_URL
    }?returnToURL=${window.location.pathname}`;
    window.sessionStorage.setItem(
      'addToWishlistPostAuth',
      JSON.stringify({
        product,
        selectedSku,
      }),
    );
    window.sessionStorage.setItem('stlParentProductId', stlParentProductId);
    window.location.href = signInUrl;
  }

  render() {
    return (
      <Fragment>
        <span className="saveToWishlist" onClick={this.onClickSaveToWishlist}>
          <EyeBrow7>{SAVE_TO_WISHLIST}</EyeBrow7>
        </span>
        {/* <ErrorMessage message={this.props.product.addToBagError} /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const product = getProduct(state, productId);
  const stlParentProductId = get(
    state,
    'shopTheLookReducer.stlParentProductId',
    '',
  );
  const device = get(state, 'device', {});

  return {
    product,
    user: state.user,
    dtSignInToggle: state.toggles.DT_SIGN_IN,
    stlParentProductId,
    device,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  showAddToBagError: (...args) =>
    dispatch(showAddToBagError(productId, ...args)),
  clearError: () => dispatch(clearError(productId)),
  addWishlist: (...args) => dispatch(addWishlist(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveToWishlist);
