import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRowWrapper } from './SizeGuideTable.styles';

export const TableRow = ({ rowData, isHeader }) => {
  return (
    <TableRowWrapper>
      {Object.keys(rowData).map((key, cellIndex) => (
        <TableCell
          key={cellIndex}
          variant={isHeader || cellIndex === 0 ? 'head' : 'body'}
          align="center"
        >
          {isHeader ? rowData[key].label : rowData[key].display}
        </TableCell>
      ))}
    </TableRowWrapper>
  );
};

TableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  isHeader: PropTypes.bool,
};

TableRow.defaultProps = {
  isHeader: false,
};
