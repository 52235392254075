import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import SizeGuideModal from './SizeGuideModal';
import BottomSheet from '../BottomSheet/BottomSheet';
import SizeGuideBottomSheet from './SizeGuideBottomSheet';

const SizeGuideText = styled.p`
  font-size: 13px;
  text-decoration: underline;
  margin-bottom: 8px;
`;

export default function SizeGuide({
                 product,
                 sizeGuideResponse,
                 isMobile,
                 updateBGSGState,
                 updateVendorSGState,
               }) {
                 const [isOpen, setOpen] = useState(false);
                 const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(
                   false,
                 );
                 const touchStartRef = useRef(null);
                 const touchCurrentRef = useRef(null);
                 const [scrollY, setScrollY] = useState(0);

                 const sizeGuideName = product?.designer?.name
                   ? `${product?.designer?.name} Size Guide`
                   : 'Size Guide';

                 function handleClick(event) {
                  if (sizeGuideName?.indexOf('Bergdorf Goodman') > -1) {
                    updateBGSGState();
                  } else {
                    updateVendorSGState();
                  }
                  event.preventDefault();
                  if (isMobile) {
                    setIsBottomSheetOpen(true);
                    if (window) setScrollY(window?.scrollY);
                  } else {
                    setOpen(true);
                  }
                 }

                 const handleClose = () => {
                   setOpen(false);
                 };

                 const handleBottomSheetClose = () => {
                   setIsBottomSheetOpen(false);
                 };

                 function getLowestLevelKey(hierarchyElement) {
                   const [lastKey] = Object?.keys(hierarchyElement)
                     .filter(key => key?.includes('level'))
                     .sort((a, b) => b?.localeCompare(a));

                   return lastKey;
                 }

                 function getCategoryName(product) {
                   const { hierarchy = [] } = product;
                   const [firstHierarchy] = hierarchy;

                   if (!firstHierarchy) {
                     return '';
                   }

                   const lowestLevelKey = getLowestLevelKey(firstHierarchy);

                   if (!lowestLevelKey) {
                     return '';
                   }

                   return `${firstHierarchy[lowestLevelKey]}`;
                 }

                 // Pull down close
                 const handleTouchStart = e => {
                   touchStartRef.current = e?.touches?.[0]?.clientY;
                 };

                 const handleTouchMove = e => {
                   touchCurrentRef.current = e?.touches?.[0]?.clientY;
                 };

                 const handleTouchEnd = () => {
                   if (touchStartRef?.current && touchCurrentRef?.current) {
                     const swipeDistance =
                       touchCurrentRef?.current - touchStartRef?.current;
                     if (swipeDistance > 50) {
                       setIsBottomSheetOpen();
                     }
                   }
                   touchStartRef.current = null;
                   touchCurrentRef.current = null;
                 };

                 const sizeGuideBottomSheet = (
                   <SizeGuideBottomSheet
                     title={getCategoryName(product)}
                     secondaryTitle={sizeGuideName}
                     sizeGuides={sizeGuideResponse}
                     onClose={handleBottomSheetClose}
                     handleTouchStart={handleTouchStart}
                     handleTouchMove={handleTouchMove}
                     handleTouchEnd={handleTouchEnd}
                     isSizeGuide
                   />
                 );

                 return (
                   <>
                     <a href="#" onClick={handleClick}>
                       <SizeGuideText>{sizeGuideName}</SizeGuideText>
                     </a>
                     <SizeGuideModal
                       isOpen={isOpen}
                       onClose={handleClose}
                       title={getCategoryName(product)}
                       secondaryTitle={sizeGuideName}
                       sizeGuides={sizeGuideResponse}
                     />
                     <BottomSheet
                       isBottomSheetOpen={isBottomSheetOpen}
                       setIsBottomSheetOpen={handleBottomSheetClose}
                       scrollY={scrollY}
                       child={sizeGuideBottomSheet}
                     />
                   </>
                 );
               }
