import isEmpty from 'lodash/isEmpty';

export const booleanToString = value => Boolean(value).toString();

export const isSwatchProduct = options => {
  const hasSwatch = (options.productOptions || []).some(
    productOption =>
      productOption.label === 'color' && !isEmpty(productOption.values[0].url),
  );
  return booleanToString(hasSwatch);
};

export const isExclusiveProduct = productFlags => {
  const hasProductFlag = productFlags ? productFlags.isOnlyAtNM : [];
  return booleanToString(hasProductFlag);
};

export const getSaleType = state => {
  if (state.details.clearanceItem) return 'Clearance';
  if (state.price.adornments) return 'Sale';
  return 'Regular';
};

export const getEDWPricingType = state => {
  if (
    !isEmpty(state.promotions) &&
    state.price.promotionalPrice &&
    state.price.adornments
  )
    return 'CP';
  if (state.price.adornments) return 'C';
  if (state.promotions && state.price.promotionalPrice) return 'RP';
  return 'R';
};

export const getDropShipFlag = state => {
  return state.skus.some(sku => sku.dropShip);
};

export const getTotalSkuCount = state => {
  return state.skus.length;
};

export const getAvailableSkuCount = state => {
  return state.skus
    .map(sku => booleanToString(sku.sellable))
    .filter(availableSku => availableSku === 'true').length;
};

export const getHierarchy = state => {
  const hierarchyLevels = [];
  if (!isEmpty(state?.hierarchy)) {
    for (const [, value] of Object.entries(state.hierarchy[0])) {
      hierarchyLevels.push(value);
    }
  }
  return hierarchyLevels;
};

export const getOriginalPrice = product => {
  const { price } = product;
  const adornments = price?.adornments || [];
  if (adornments?.length > 0) {
    const originalPriceObj = adornments.find(
      adornment => adornment?.label?.toLowerCase() === 'original',
    );
    return originalPriceObj ? originalPriceObj?.price : price?.retailPrice;
  }
  return price?.retailPrice;
};

export const getProductBadge = state => {
  const BADGES_ORDER = [
    'PRE-ORDER',
    'BACK-ORDER',
    'NEW ARRIVAL',
    'EXCLUSIVELY BG',
    `LINDA'S AT BG"`,
    'GIFT CARD EVENT',
    'CONSCIOUS CURATION',
    'GIFT CARD',
  ];

  const displayableBadges = [];
  BADGES_ORDER.forEach(value => {
    switch (value) {
      case 'PRE-ORDER':
        state.preOrder && displayableBadges.push(value);
        break;
      case 'BACK-ORDER':
        state.backOrder && displayableBadges.push(value);
        break;
      case 'NEW ARRIVAL':
        state.productFlags &&
          state.productFlags.isNewArrival &&
          displayableBadges.push(value);
        break;
      case 'EXCLUSIVELY BG':
        state.productFlags &&
          state.productFlags.isOnlyAtNM &&
          displayableBadges.push(value);
        break;
      case "LINDA'S AT BG":
        Array.isArray(state.promotions) &&
          state.promotions.some(
            v =>
              v.templateHTML &&
              v.templateHTML
                .toLowerCase()
                .includes("LINDA'S AT BG".toLowerCase()) &&
              displayableBadges.push(value),
          );
        break;
      case 'GIFT CARD EVENT':
        Array.isArray(state.promotions) &&
          state.promotions.some(
            v =>
              v.templateHTML &&
              v.templateHTML
                .toLowerCase()
                .includes('GIFT CARD EVENT'.toLowerCase()) &&
              displayableBadges.push(value),
          );
        break;
      case 'CONSCIOUS CURATION':
        Array.isArray(state.promotions) &&
          state.promotions.some(
            v =>
              v.templateHTML &&
              v.templateHTML
                .toLowerCase()
                .includes('CONSCIOUS CURATION'.toLowerCase()) &&
              displayableBadges.push(value),
          );
        break;
      case 'GIFT CARD':
        state.giftCard && displayableBadges.push(value);
        break;
      default:
    }
  });
  return displayableBadges.length ? displayableBadges[0] : 'NO BADGE';
};

export default state => {
  return {
    product_type: ['non-group'],
    product_brand: state.designer && state.designer.name,
    product_id: [state.id],
    badge_type: getProductBadge(state),
    product_name: [state.name],
    product_cmos_catalog_id: [state.metadata.cmosCatalogId],
    product_cmos_item: [state.metadata.cmosItem],
    product_pim_style: [state.metadata.pimStyle],
    product_price: [state.price.retailPrice],
    product_price_original: [getOriginalPrice(state)],
    product_pricing_adornment_flag: [booleanToString(state.price.adornments)],
    product_swatch: isSwatchProduct(state.options || { productOptions: [] }),
    product_cmos_sku: state.skus.map(sku => sku.metadata.cmosSkuId),
    product_expected_availability: state.skus.map(
      sku => sku.expectedShipDays || '',
    ),
    product_sellable_sku: state.skus.map(sku => booleanToString(sku.sellable)),
    product_hierarchy: getHierarchy(state),
    product_sale_type: [getSaleType(state)],
    product_suppress_checkout_flag: booleanToString(
      state.details.suppressCheckout,
    ),
    product_available: [booleanToString(state.displayable)],
    product_dropship_flag: [booleanToString(getDropShipFlag(state))],
    product_nm_exclusive: [isExclusiveProduct(state.productFlags)],
    total_sku_count: getTotalSkuCount(state),
    available_sku_count: getAvailableSkuCount(state),
    edw_pricing_adornment: [getEDWPricingType(state)],
    product_id_parent: [''],
  };
};
