import { BodyB1, EyeBrow1 } from 'bgo-common/Styleguide/Typography';
import { isMobile } from 'client-utils/utilities-page';
import { mark, measure } from 'client/utilities/utilities-performance';
import get from 'lodash/get';
import CallForAvailability from 'pdp/components/ProductPage/components/Checkout/callForAvailability';
import 'pdp/components/ProductPage/components/Checkout/callForAvailability.scss';
import ItemNotAvailable from 'pdp/components/ProductPage/components/Checkout/itemNotAvailable';
import 'pdp/components/ProductPage/components/Checkout/monogramHelpline.scss';
import FavComponent from 'pdp/components/ProductPage/optimizationcomponents/FavoriteComponent/favoriteComponent';
import {
  ASSISTANCE_REQUIRED,
  MONOGRAM_HELPLINE,
  MONOGRAM_HELPLINE_MOBILE,
  MONOGRAM_HELPLINE_NUMBER,
} from 'pdp/constants';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Button from 'shared/components/Button/button';
import CheckoutOptionInfo from './checkoutOptionInfo';

const hasMixedTypeCustomizationOptionsIncludingPDP = product => {
  let hasMixedType = false;
  const types = [];
  if (product.customization && product.customization.customizationOptions) {
    product.customization.customizationOptions.forEach(element => {
      types.push(element.type);
      if (element.type !== 'PDP') {
        hasMixedType = true;
      }
    });
  }
  return hasMixedType && types.filter(type => type === 'PDP').length > 0;
};

export const shouldDisplayMonogramHelpLineText = product => {
  const {
    isCustomizable = false,
    customizationSupported = true,
    customization = {},
  } = product;
  const behaviorCode = get(customization, 'behaviorCode', '');
  return (
    isCustomizable &&
    (!customizationSupported ||
      hasMixedTypeCustomizationOptionsIncludingPDP(product)) &&
    behaviorCode !== 'N'
  );
};

export const renderCheckoutOrHelplineText = (
  product,
  defaultCheckoutInfo,
  itemNotAvailableMessage,
) => {
  const { displayable = false } = product;

  const suppressCheckout = get(product, 'details.suppressCheckout', false);

  const monogramHelplineContent = (
    <span>
      {MONOGRAM_HELPLINE}
      <br />
      {MONOGRAM_HELPLINE_NUMBER}
    </span>
  );

  const monogramHelplineMobileContent = (
    <span>
      {MONOGRAM_HELPLINE_MOBILE}
      <a href={`tel:${MONOGRAM_HELPLINE_NUMBER}`}>
        <Button
          ariaLabel="HelpLine Number"
          value={`Call ${MONOGRAM_HELPLINE_NUMBER}`}
          className="cta_text_link_one"
        />
      </a>
    </span>
  );

  const MonogramHelpline = (
    <div className="grid-100 checkout-option__info__spacing">
      <div className="monogramHelpline">
        <EyeBrow1>
          <span className="assistance-required-text">
            {ASSISTANCE_REQUIRED}
          </span>
        </EyeBrow1>
        <BodyB1>
          {isMobile() ? monogramHelplineMobileContent : monogramHelplineContent}
        </BodyB1>
      </div>
    </div>
  );

  const suppressCheckoutSection = suppressCheckout ? (
    <CallForAvailability />
  ) : (
    defaultCheckoutInfo
  );

  const getCheckoutInfo = product => {
    return shouldDisplayMonogramHelpLineText(product)
      ? MonogramHelpline
      : suppressCheckoutSection;
  };

  return displayable ? getCheckoutInfo(product) : itemNotAvailableMessage;
};

class CheckoutOptionRenderer extends React.Component {
  constructor() {
    super();
    mark('product options (construct)');
  }

  componentDidMount() {
    measure('product options (TTI)');
    measure(
      'product options (self render time)',
      'product options (construct)',
    );
  }

  render() {
    const {
      product,
      boss,
      showAddToFavorites,
      isGroupPDP,
      disableCustomDropDown,
      hasQuantity,
      isFromShopTheLook,
      isFromQL,
      updateQuantityState,
      updateColorState,
      updateSizeState,
      updateNotifyState,
      updateBGSGState,
      updateVendorSGState,
      updateFindInStoreState,
    } = this.props;
    const { metadata = {} } = product;

    const itemNotAvailableMessage = (
      <Fragment>
        <ItemNotAvailable>
          <span className="undisplayable__code">{`${metadata.cmosCatalogId}_${metadata.cmosItem}`}</span>
        </ItemNotAvailable>
        {showAddToFavorites ? <FavComponent productId={product.id} /> : null}
      </Fragment>
    );

    const defaultCheckoutInfo = (
      <CheckoutOptionInfo
        product={product}
        boss={boss}
        isGroupPDP={isGroupPDP}
        disableCustomDropDown={disableCustomDropDown}
        hasQuantity={hasQuantity}
        isFromShopTheLook={isFromShopTheLook}
        isFromQL={isFromQL}
        updateQuantityState={updateQuantityState}
        updateColorState={updateColorState}
        updateSizeState={updateSizeState}
        updateNotifyState={updateNotifyState}
        updateBGSGState={updateBGSGState}
        updateVendorSGState={updateVendorSGState}
        updateFindInStoreState={updateFindInStoreState}
      />
    );
    return renderCheckoutOrHelplineText(
      product,
      defaultCheckoutInfo,
      itemNotAvailableMessage,
    );
  }
}

const mapStateToProps = state => {
  return {
    showAddToFavorites: state.toggles.PDP_FAVORITE,
  };
};

export default connect(mapStateToProps)(CheckoutOptionRenderer);
