import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { US_COUNTRY_CODE, REQUIRED_MONOGRAM } from 'pdp/constants';
import findInStoreImg from 'bgo-common/assets/images/find-in-store.svg';
import BopsErrorForReplenishment from 'productpage/components/BopsErrorForReplenishment/bopsErrorForReplenishment';
import BopsNoSelectionErrorForReplenishment from 'pdp/components/ProductPage/optimizationcomponents/BopsNoSelectionErrorForReplenishment/bopsNoSelectionErrorForReplenishment';
import getProduct from 'pdp/components/ProductPage/selectors/getProduct';
import {
  getPerformanceLogLevel,
  markTTI,
  markStartTTD,
  markStart,
} from 'client-utils/utilities-performance';
import ErrorMessage from 'pdp/components/ProductPage/components/ErrorMessage/errorMessage';
import validatePDP, { BOPS } from '../../pdpPageValidator';
import {
  openBops,
  showBopsError,
  clearError,
  clearStores,
  setBopsErrorForReplenishment,
  setBopsNoSelectionErrorForReplenishment,
  getStores,
} from '../../actions';

export class DumbBopsButton extends Component {
  constructor() {
    super();
    this.onClickOpenBops = this.onClickOpenBops.bind(this);
    this.onClickGetStores = this.onClickGetStores.bind(this);
  }

  onClickGetStores() {
    markStart('PDP_TimeToFindInStoreSearch');
    const {
      product = {},
      selectedSku = {},
      defaultStoreValues = {},
    } = this.props;
    const { quantity = 0 } = product;
    this.props.getStores(
      defaultStoreValues.addressValue,
      selectedSku.id,
      quantity,
    );
    this.onClickOpenBops();
  }

  onClickOpenBops() {
    if (getPerformanceLogLevel() > 3) {
      markTTI('PDP_FindInStoreButton');
    }
    markStartTTD('PDP_FindInStoreModal');
    this.props.clearError();
    const {
      product,
      selectedSku,
      skuMediaUrl,
      vendorQtyRestrictions,
      excludeBackOrderBOPSToggle,
    } = this.props;
    if (
      product.replenishable &&
      (product.replenishInterval === undefined ||
        product.replenishInterval === -1)
    ) {
      this.props.setBopsNoSelectionErrorForReplenishment();
      return;
    } else if (product.replenishable && product.replenishInterval) {
      this.props.setBopsErrorForReplenishment();
      return;
    }
    const errorMessage = validatePDP(
      product,
      selectedSku,
      vendorQtyRestrictions,
      BOPS,
      false,
      false,
      excludeBackOrderBOPSToggle,
    );
    if (errorMessage) {
      this.props.showBopsError(errorMessage);
      return;
    }
    this.props.updateFindInStoreState();
    this.props.clearStores();
    this.props.openBops(product, selectedSku, skuMediaUrl);
  }

  isEligibleForPickupInStore() {
    const { product, countryCode } = this.props;
    const behaviorCode = get(product, 'customization.behaviorCode');
    const pickupInStoreEligible = get(product, 'details.pickupInStoreEligible');
    if (behaviorCode === REQUIRED_MONOGRAM) {
      return false;
    }
    return pickupInStoreEligible === true && countryCode === US_COUNTRY_CODE;
  }

  render() {
    const { showStoresWithoutSearch } = this.props;
    const bopsButton = (
      <div
        id="bopsButton"
        className="grid-100 tablet-grid-100 mobile-grid-100 bops-button grid-parent"
      >
        <ErrorMessage message={this.props.errorMessage} />
        <BopsErrorForReplenishment
          isError={this.props.product.bopsErrorForReplenishment}
        />
        <BopsNoSelectionErrorForReplenishment
          isError={this.props.product.bopsNoSelectionErrorForReplenishment}
          name={this.props.product.name}
        />
        <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent optimizedBopsButton">
          <button
            className="bopsButtonControl"
            onClick={
              !showStoresWithoutSearch
                ? this.onClickOpenBops
                : this.onClickGetStores
            }
          >
            <img
              alt="Find In Store"
              aria-hidden="true"
              className="bops-button-img"
              src={findInStoreImg}
            />
            <span className="bops-button-label">Find In Store</span>
          </button>
        </div>
      </div>
    );

    return this.isEligibleForPickupInStore() && bopsButton;
  }
}

const mapStateToProps = (state, { product: { id: productId } }) => {
  const product = getProduct(state, productId);

  return {
    errorMessage: product.bopsError,
    vendorQtyRestrictions: state.productCatalog.vendorQtyRestrictions,
    countryCode: state.locale.countryCode,
    excludeBackOrderBOPSToggle: state.toggles.EXCLUDE_BACKORDER_FOR_BOPS,
    showStoresWithoutSearch: state.toggles.PDP_SHOW_STORES_WITHOUT_SEARCH,
    defaultStoreValues: state.productCatalog.defaultStoreValues,
  };
};

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  openBops: (...args) => dispatch(openBops(...args)),
  showBopsError: (...args) => dispatch(showBopsError(productId, ...args)),
  clearError: () => dispatch(clearError(productId)),
  clearStores: () => dispatch(clearStores()),
  setBopsErrorForReplenishment: () =>
    dispatch(setBopsErrorForReplenishment(productId)),
  setBopsNoSelectionErrorForReplenishment: () =>
    dispatch(setBopsNoSelectionErrorForReplenishment(productId)),
  getStores: (...args) => dispatch(getStores(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DumbBopsButton);
