import httpWithLogging from 'universal/http-client';
import get from 'lodash/get';
import logger from 'server-utils/logger';
import { DELEVERY_DATE_PREFIX } from '../../constants';

export const RESOLVED_PRODUCT = 'RESOLVED_PRODUCT';
export const RESOLVED_WISHLIST = 'RESOLVED_WISHLIST';
export const RESOLVED_WISHLIST_PDP = 'RESOLVED_WISHLIST_PDP';
export const RESOLVED_WISHLIST_PRODUCTS = 'RESOLVED_WISHLIST_PRODUCTS';
export const REMOVE_WISHLIST = 'REMOVE_WISHLIST';
export const ADD_WISHLIST = 'ADD_WISHLIST';
export const UPDATE_WISHLIST = 'UPDATE_WISHLIST';
export const LOADING_REMOVE_WISHLIST = 'LOADING_REMOVE_WISHLIST';
export const REJECTED_REMOVE_WISHLIST = 'REJECTED_REMOVE_WISHLIST';

export function getWishlist(source = null) {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const headers = {
      'x-profile-id': get(session, 'DYN_USER_ID', 't2101109663'),
    };
    const GET_WISHLIST_ACTION_TYPE =
      source === 'PDP' ? RESOLVED_WISHLIST_PDP : RESOLVED_WISHLIST;
    const requestURI = NMConfig.API_BG_WISHLIST;
    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const wishList = successResponse.data || {};
        dispatch({ type: GET_WISHLIST_ACTION_TYPE, payload: wishList });
      })
      .catch(e => {
        logger.error('Exception in getWishlist call:', e);
      });
  };
}

export function getWishlistProducts() {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const headers = {
      'x-profile-id': get(session, 'DYN_USER_ID', 't2101109663'),
    };

    const requestURI = NMConfig.API_BG_WISHLIST;

    const requestApi = httpWithLogging(state, 10000);
    /* eslint-disable consistent-return */
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        const wishList = successResponse.data || {};
        dispatch({ type: RESOLVED_WISHLIST, payload: wishList });

        const productIds = [];
        const skuIds = [];
        if (wishList.items && wishList.items.length > 0) {
          wishList.items.forEach(item => {
            productIds.push(item.productId);
            skuIds.push(item.skuId);
          });

          const requestParams = `?productIds=${productIds.join(
            ',',
          )}&skuIds=${skuIds.join(',')}`;
          const compositeEnabled = get(
            state,
            'toggles.PDP_COMPOSITE_MODE',
            false,
          );
          const requestURIN = compositeEnabled
            ? `${NMConfig.API_COMPOSITE_MINIFIED_PRODUCTS}${requestParams}`
            : `${NMConfig.API_MINIFIED_PRODUCTS}${requestParams}`;
          const requestApi = httpWithLogging(state, 10000);
          return requestApi
            .get(requestURIN, { headers })
            .then(successResponse => {
              const type = RESOLVED_WISHLIST_PRODUCTS;
              dispatch({ type, payload: successResponse.data });
            })
            .catch(e => {
              logger.error('Exception in get minified products call:', e);
            });
        } else {
          const type = RESOLVED_WISHLIST_PRODUCTS;
          dispatch({ type, payload: successResponse.data });
        }
      })
      .catch(e => {
        logger.error('Exception in getWishlist call:', e);
      });
    /* eslint-enable consistent-return */
  };
}

export function removeWishlist(itemId, showLoading = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const headers = {
      'x-profile-id': get(session, 'DYN_USER_ID', ''),
    };
    if (showLoading) {
      dispatch({ type: `${LOADING_REMOVE_WISHLIST}_${itemId}` });
    }
    const requestURI = `${NMConfig.API_BG_WISHLIST}/${itemId}`;
    const requestApi = httpWithLogging(state, 10000);
    return requestApi
      .delete(requestURI, { headers })
      .then(() => {
        if (showLoading) {
          dispatch({ type: `${REMOVE_WISHLIST}_${itemId}` });
        }
        dispatch({ type: REMOVE_WISHLIST, payload: itemId });
      })
      .catch(e => {
        if (showLoading) {
          dispatch({ type: `${REJECTED_REMOVE_WISHLIST}_${itemId}` });
        }
        logger.error('Exception in removeWishlist call:', e);
      });
  };
}

export function addWishlist(product, selectedSku, existingItemId) {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const headers = {
      'x-profile-id': get(session, 'DYN_USER_ID', 't2101109663'),
    };
    let replenishInterval = '';
    const deliveryDate = get(product, 'deliveryDate', '');
    const selectedReplenishInterval = get(product, 'replenishInterval', '');
    if (deliveryDate) {
      replenishInterval = `${DELEVERY_DATE_PREFIX}${deliveryDate}`;
    }
    if (selectedReplenishInterval) {
      replenishInterval = selectedReplenishInterval.toString();
    }
    const body = {
      itemId: selectedSku?.id,
      productId: product.id,
      // siCodeSelections: product.siCodeSelections,
      existingItemId: existingItemId || '',
      replenishInterval,
      // isMonogramSelected: product.isMonogramSelected,
    };
    const requestURI = NMConfig.API_BG_WISHLIST;

    const requestApi = httpWithLogging(state, 10000);

    return requestApi
      .post(requestURI, body, { headers })
      .then(() => {
        const wishlistData = {
          skuId: selectedSku?.id,
          productId: product.id,
          existingItemId: existingItemId || '',
          replenishInterval,
          sku: selectedSku,
        };

        if (existingItemId) {
          dispatch({ type: UPDATE_WISHLIST, payload: wishlistData });
        } else {
          dispatch({ type: ADD_WISHLIST, payload: wishlistData });
        }
      })
      .catch(e => {
        logger.error('Exception in addWishlist call:', e);
      });
  };
}
