import { withStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  Tab as MaterialTab,
  Tabs as MaterialTabs,
} from '@material-ui/core';
import styled from 'styled-components';

export const OverflowWrapper = styled.div`
  overflow-x: auto;
  border: 1px solid #000000;
  margin-top: 39px;

  &.no-margin {
    margin: 0;
  }
`;

export const Tabs = withStyles({
  root: {
    minHeight: 36,
    position: 'relative',
    '&::after': {
      content: '""',
      height: 1,
      backgroundColor: '#b7b7b7',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
})(MaterialTabs);

export const Tab = withStyles({
  root: {
    fontFamily: 'Larsseit',
    minWidth: 'auto',
    minHeight: 36,
    color: '#828282',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 400,
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 24,
    '&$selected': {
      color: '#000000',
    },
  },
  textColorInherit: {
    opacity: 1,
  },
  selected: {},
})(MaterialTab);

export const MetricsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Metric = withStyles({
  root: {
    fontSize: 11,
    lineHeight: 1,
    textTransform: 'uppercase',
    fontFamily: 'Larsseit',
    padding: '8px 11px 13px',
    height: 39,
    color: props => (props.selected ? '#000' : '#5c5c5c'),
    '&:hover': {
      color: '#000000',
    },
  },
})(ButtonBase);
