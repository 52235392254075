import React from 'react';
import PropTypes from 'prop-types';

export function TabPanel(props) {
  const { children, value, index, label, labelledBy, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`${label}-${index}`}
      aria-labelledby={`${labelledBy}-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
  children: '',
};
