import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-height: 90vh;
  overflow-y: auto;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  padding: 15px 16px 31px 16px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const TitleContainer = styled.div`
  padding: 8px 16px 18px 16px;
`;

export const SecondaryTitle = styled.p`
  font-size: 14px;
  font-family: Larsseit;
  font-weight: 400;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const PrimaryTitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: -8px;
`;

export const Container = styled.div`
  padding: 0 16px;
  & + & {
    margin-top: 33px;
  }
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 13px;
  font-family: Larsseit;
  margin-bottom: 8px;
`;

export const StoreContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px 45px 16px;

  a {
    font-weight: 400;
    font-size: 13px;
    font-family: Larsseit;
    text-decoration: underline;
  }
`;

export const CallText = styled.div`
  font-weight: 400;
  font-size: 13px;
  font-family: Larsseit;
`;

export const RightIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const LeftIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const Title = styled.div`
  font-size: 14px;
  font-family: Larsseit;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Arrow = styled.span`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.2s;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
`;
