import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const SelectBox = styled.div`
  background-color: white;
  border: 1px solid #d0d0d0;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 40px 0 10px;
  border-radius: 0;
  position: relative;
  height: 46px;
`;

export const Placeholder = styled.div`
  font-family: Larsseit;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #000;
  pointer-events: none;
  transition: all 0.2s;
  font-size: 14px;
  ${props =>
    props.active &&
    css`
      top: 10px;
      font-size: 11px;
      color: rgb(92, 92, 92);
    `}
`;

export const PlaceholderText = styled.div`
  font-family: Larsseit;
  position: absolute;
  top: 24px;
  left: 10px;
  color: #5c5c5c;
  font-size: 14px;
  white-space: ${props =>
    props?.isQuantitySelectorEligible ? 'nowrap' : 'normal'};
  overflow: ${props =>
    props?.isQuantitySelectorEligible ? 'hidden' : 'visible'};
  text-overflow: ${props =>
    props?.isQuantitySelectorEligible ? 'ellipsis' : 'unset'};
  width: ${props => (props?.isQuantitySelectorEligible ? '88%' : '100%')};
`;

export const Arrow = styled.span`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.2s;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
`;

export const BottomSheetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: relative;
`;

export const PullHandle = styled.div`
  width: 32px;
  height: 4px;
  background-color: #e7e7e8;
  border-radius: 100px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-height: ${({ isFitguidance }) => (isFitguidance ? '80vh' : '84vh')};
  overflow-y: auto;
`;

export const SizeText = styled.h1`
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin: 15px;
`;

export const FitGuidanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const FitGuidance = styled.div`
  font-size: 13px;
  strong {
    font-weight: 700;
  }
`;

export const Option = styled.div`
  padding: 15px 24px;
  cursor: pointer;
  font-size: 16px;
  font-family: Larsseit;
  font-weight: 400;

  &:hover {
    background-color: rgb(92, 92, 92);
  }

  ${props =>
    props.selected &&
    css`
      background-color: grey;
    `}

  &.rebrand_bg_color {
    background-color: #fff9f5 !important;
  }

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.disabled ? '#ccc' : 'black')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const SizeGuideText = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-family: Larsseit;
  text-decoration-line: underline;
  margin-bottom: 0px;
`;

export const BottomSheetGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledGridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  width: 90%;
`;
